<template>
  <layout-vertical v-if="initialDataLoaded">

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import store from "@/store";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      initialDataLoaded : false
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  async  created() {
    let _this = this;
    await store.dispatch('initialConfigModule/fetchInitialConfig').then(function(response){


      let user = response.userData;
      const { userData } = user

      useJwt.setToken(user.accessToken)
      //useJwt.setRefreshToken(response.data.refreshToken)
      localStorage.setItem('userData', JSON.stringify(userData))

      _this.$ability.update(userData.ability)
      _this.initialDataLoaded = true;
    });
  }
}
</script>
